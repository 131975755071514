import { getOnboardingFlowParams } from "@/lib/utils";
import { NobfType } from "@/src/models/common.model";

const pagePath = {
  users: {
    uploadInsurance(
      orgId: number,
      backUrl?: string,
      type?: NobfType,
      requiredSubscription?: boolean,
    ) {
      const params = getOnboardingFlowParams({
        type,
        orgId,
        backUrl,
        requiredSubscription,
      });
      return `/user/upload/insurance?${params}`;
    },
    uploadTrainings(
      orgId: number,
      backUrl?: string,
      type?: NobfType,
      requiredSubscription?: boolean,
    ) {
      const params = getOnboardingFlowParams({
        type,
        orgId,
        backUrl,
        requiredSubscription,
      });
      return `/user/upload/trainings?${params}`;
    },
  },

  error: {
    unSupported(isLab?: boolean) {
      return isLab ? "/hosting/unsupported" : "/unsupported";
    },
  },
  lab: {
    account() {
      return "/hosting/account";
    },
    scientific_process_list() {
      return "/hosting/scientific-process";
    },
  },
  services: {
    item(slug: string) {
      return `/services/${slug}`;
    },
  },

  company: {
    invalidInvite() {
      return "/invalid-invite";
    },
  },
  organizations: {
    info(orgId?: number, orgSlug?: string, slug?: string) {
      if (slug) return `/organizations/${slug}`;
      return `/organizations/${orgSlug}-${orgId}`;
    },
    contracts(
      contractId: number,
      orgId: number,
      type: NobfType,
      backUrl?: string,
      requiredSubscription?: boolean,
    ) {
      const params = getOnboardingFlowParams({
        type,
        backUrl,
        requiredSubscription,
        orgId,
      });
      return `/contracts/${contractId}?${params}`;
    },
    buySubscription(
      orgId: number,
      orgSlug: string,
      backUrl?: string,
      type?: NobfType,
    ) {
      const params = getOnboardingFlowParams({
        type,
        backUrl,
      });
      return `/organizations/${orgSlug}-${orgId}/buy-subscription?${params}`;
    },
    access(
      slug: string,
      backUrl?: string,
      type?: NobfType,
      requiredSubscription?: boolean,
    ) {
      const params = getOnboardingFlowParams({
        type,
        backUrl,
        requiredSubscription,
      });
      return `/organizations/${slug}/access-request?${params}`;
    },
  },
  booking: {
    detail(id: string) {
      return `/bookings/${id}`;
    },
  },
};

export default pagePath;
