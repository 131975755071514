import { Filters } from "@/components/home-listings/interface";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface Props {
  filters: Filters;
  setFilters: any;
}

export function ListingTypeSelect({ filters, setFilters }: Props) {
  const handleChange = (value: string) => {
    setFilters((prev: any) => ({
      ...prev,
      type: value,
    }));
  };
  return (
    <Select
      defaultValue="all"
      onValueChange={handleChange}
      value={filters.type}
    >
      <SelectTrigger
        className="p3-medium h-12 whitespace-nowrap text-coffee-900"
        aria-label="Select listing type"
      >
        <SelectValue placeholder="Type" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all" className="p3">
          All
        </SelectItem>
        <SelectItem value="equipment" className="p3">
          Equipment
        </SelectItem>
        <SelectItem value="service" className="p3">
          Service
        </SelectItem>
        <SelectItem value="scientific_process" className="p3 whitespace-nowrap">
          Scientific Process
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
