export interface Tag {
  id: number;
  label: string;
  value: string;
  count: number;
}

export interface Manufacturer {
  id: number;
  label: string;
  value: string;
}

interface Item {
  value: number;
  label: string;
}

export interface Filters {
  tags?: { [key: string]: Item };
  manufacturers?: { [key: string]: Item };
  labs?: { [key: string]: Item };
  text?: string;
  minPrice?: string;
  maxPrice?: string;
  type?: string;
}

export const defaultFilters: Filters = { text: "", tags: {}, type: "all" };

export interface Calendar {
  id: number;
  summary: string;
  description: string;
}
