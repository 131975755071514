import { Microscope } from "lucide-react";

export const NoResultFound = () => (
  <div className="flex flex-col items-center justify-center gap-5">
    <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-interface-white">
      <Microscope size={32} strokeWidth={1} />
    </div>
    <div className="p4 text-center">No results found</div>
  </div>
);
