import { X } from "lucide-react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { Filters } from "@/components/home-listings/interface";

interface Props {
  filters: Filters;
  setFilters: any;
  fetchFn: (url: string) => Promise<any>;
  name: "manufacturers" | "tags" | "labs";
  path: string;
  params?: any;
}

interface Item {
  value: number;
  label: string;
}

const MultiValueRemove = (props: any) => (
  <div className="ml-1 flex cursor-pointer items-center justify-center pr-1 [&>[role=button]]:hover:bg-transparent">
    <components.MultiValueRemove {...props}>
      <X size={24} strokeWidth={1} className="align-middle" />
    </components.MultiValueRemove>
  </div>
);

export function MultiSelectFilter(props: Props) {
  async function loadOptions(inputValue: string) {
    const query = new URLSearchParams(props.params);
    if (inputValue) {
      query.append("name", inputValue);
    }

    const options = await props.fetchFn(
      `/${props.path}${query.toString() ? "?" + query.toString() : ""}`,
    );
    return options;
  }

  function onChange(values: any) {
    props.setFilters((prev: any) => ({
      ...prev,
      [props.name]: values.reduce(
        (acc: any, item: Item) => ({
          ...acc,
          [item.value]: item,
        }),
        {},
      ),
    }));
  }

  const defaultValue = Object.values(props.filters[props.name] || {})
    .filter((item) => item)
    .map((item: any) => ({ value: item.value, label: item.label }));

  return (
    <AsyncSelect
      defaultValue={defaultValue}
      defaultOptions
      loadOptions={loadOptions}
      isMulti
      onChange={onChange}
      name={props.name}
      components={{ MultiValueRemove }}
      placeholder={`Search ${props.name}`}
      styles={{
        control: (baseStyles, { isFocused }) => ({
          ...baseStyles,
          borderColor: "hsl(var(--input))",
          height: "48px",
          backgroundColor: "#FFFEFE",
          boxShadow: "none !important",
          ":active": {
            boxShadow: "none !important",
          },
          ":hover": {
            borderColor: "#71706E !important",
          },
        }),
        container: (styles, { isFocused }) => ({
          ...styles,
          borderColor: "#71706E",
          boxShadow: "none !important",
          ":hover": {
            boxShadow: "none !important",
          },
        }),
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: "#EADFCA",
            border: "none",
            borderRadius: "2px",
            padding: "2px 4px 2px 10px",
            marginRight: "6px",
            marginLeft: 0,
            fontFamily: "Satoshi Medium",
            fontWeight: 500,
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: "#232D2A",
          fontSize: "14px",
          fontWeight: 500,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: "#F7F7F7",
            color: "#232D2A",
            fontSize: "14px",
            cursor: "pointer",
            borderBottom: "#71706E 1px solid",
            padding: 12,
            ":hover": {
              background: "#F0EFEF",
            },
          };
        },

        menuList: (styles) => ({
          ...styles,
          background: "transparent",
          padding: 0,
          borderRadius: "4px",
          border: "1px solid #71706E",
          zIndex: 100,
        }),
        menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
      }}
    />
  );
}
