import { ChevronLeft, ChevronRight } from "lucide-react";
import { useRef } from "react";
import useSWR from "swr";

import { Filters } from "@/components/home-listings/interface";
import { fetchTags } from "@/components/new-equipment/api";
import { Button } from "@/components/ui/button";
import { Toggle } from "@/components/ui/toggle";
import { cn } from "@/lib/utils";

interface Props {
  filters: Filters;
  setFilters: any;
}

export function EquipmentTags(props: Props) {
  const { data: tags = [] } = useSWR("/tags", fetchTags);

  function onClick(tag: any) {
    props.setFilters((prev: any) => ({
      ...prev,
      tags: {
        ...prev.tags,
        [tag.id]: prev.tags?.[tag.id] ? undefined : tag,
      },
    }));
  }

  const Tags = tags.slice(0, 20).map((tag: any) => (
    <Toggle
      pressed={!!props.filters?.tags?.[tag.id]}
      key={tag.id}
      className={cn(
        "p4-medium-mobile md:p1-bold mr-2 inline-flex h-7 items-center rounded-[56px] bg-interface-white px-4 py-2.5 capitalize text-coffee-500 hover:bg-coffee-100 hover:text-emerald-black md:h-[41px] md:min-w-[150px] md:text-sm",
        props.filters?.tags?.[tag.id] &&
          "bg-emerald-600 text-interface-white hover:bg-emerald-500 hover:text-white",
      )}
      onClick={() => onClick(tag)}
      aria-label={tag.label}
    >
      {tag.label}
    </Toggle>
  ));

  const contentWrapper = useRef(null);

  const sideScroll = (element: HTMLDivElement | null, distance: number) => {
    if (!element) return;
    element.scrollLeft += distance;
  };

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      {!!tags.length && (
        <Button
          variant="ghost"
          className="hidden h-7 shrink-0 rounded-[56px] bg-interface-white focus:outline-none md:flex md:h-11 md:w-14"
          onClick={() => sideScroll(contentWrapper.current, -500)}
          aria-label="Scroll left"
        >
          <ChevronLeft
            size="24"
            className="text-coffee-500 hover:text-coffee-900"
          />
        </Button>
      )}
      <div
        ref={contentWrapper}
        className="no-scrollbar space-x-2 overflow-x-scroll scroll-smooth whitespace-nowrap px-2 py-1"
      >
        {Tags}
      </div>
      {!!tags.length && (
        <Button
          variant="ghost"
          className="hidden h-7 shrink-0 rounded-[56px] bg-interface-white focus:outline-none md:flex md:h-11 md:w-14"
          onClick={() => sideScroll(contentWrapper.current, 500)}
          aria-label="Scroll right"
        >
          <ChevronRight
            size="24"
            className="text-coffee-500 hover:text-coffee-900"
          />
        </Button>
      )}
    </div>
  );
}
